import {useParams} from "react-router-dom";
import { useEffect, useState } from "react";
import RidInfo from "../../components/RidRow";
import { convertPrice } from '../../utils/priceConverter';
import priceFormatter from "../../utils/priceFormatter";

import RateBlock from "../../components/RateBlock";



import useAxiosPrivate from "../../hooks/useAxiosPrivate";


import "./order.css"

const statusMap = {
  4: 'Ожидает оплаты',
  5: 'Оплачен',
  6: 'Оформлен',
  7: 'Собран',
  8: 'В пути на склад',
  9: 'Отправлен получателю'
};

const Order = () => {
    const {id} = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [orderData, setOrder] = useState([]);
    const [error, setError] = useState(null);

    const [convertedPrice, setConvertedPrice] = useState('');
    

  useEffect(() => {
    // Replace 'your-backend-url' with your actual backend URL
    axiosPrivate.get("/api/v1/orders/get/"+id)
      .then(response => {
        setOrder(response.data);
      })
      .catch(error => {
        setError(error);
      });
  }, [axiosPrivate, id]);

  const totalItemsPrice = orderData.rids?.reduce((total, rid) => total + (rid.price * rid.amount), 0) || 0;
  const comissionPrice = totalItemsPrice * 0.1
  const totalDeliveryPriceChina = orderData.rids?.reduce((total, rid) => total + rid.delivery_price, 0) || 0;
  const totalDeliveryPriceMoscow = parseFloat(orderData.delivery_price) || 0;
  const totalPrice = totalItemsPrice + comissionPrice + totalDeliveryPriceChina + totalDeliveryPriceMoscow;

  useEffect(() => {
    const fetchAndConvertPrice = async () => {
      const result = await convertPrice(totalPrice);
      setConvertedPrice(result);
    };

    fetchAndConvertPrice();
  }, [totalPrice]); 

  const rids = orderData.rids?.map((rid) => {
    return <RidInfo {...rid} />
})
    
    return ( 
        <div>
            <header className="section-order__header">
              <RateBlock />
                <div className="container">
                    <h1 className="title-1">Заказ {id}</h1>
                </div>
             </header>
        <div className="info-block">
        <div>
        <div className="row">
          <span className="row-title">Дата заказа:</span>
          <span>{new Date(orderData.create_dt).toLocaleDateString()}</span>
        </div>
        <div className="row">
          <span className="row-title">Адрес доставки: </span>
          <span>{orderData.delivery_location}</span>
        </div>
        <div className="row">
          <span className="row-title">Получатель:</span>
          <span>{orderData.delivery_person}</span>
        </div>
        <div className="row">
          <span className="row-title">Телефон:</span>
          <span>{orderData.delivery_person_phone}</span>
        </div>
        <div className="row">
          <span className="row-title">Статус:</span>
          <span>{statusMap[orderData.status]}</span>
        </div>
        </div>
        <div>
       
        <div className="row">
                            <span className="row-title">Товары:</span>
                            <span>{priceFormatter(totalItemsPrice)}¥</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Сервисный сбор:</span>
                            <span>{priceFormatter(comissionPrice)}¥</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Доставка по китаю:</span>
                            <span>{priceFormatter(totalDeliveryPriceChina)}¥</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Доставка в Москву:</span>
                            <span>{priceFormatter(totalDeliveryPriceMoscow)}¥</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Итого:</span>
                            <span>{convertedPrice}</span>
                        </div>
       
        </div>
        </div>

        {rids}
    </div>
     );
}
 
export default Order;