import React from 'react';
import { useEffect, useState } from "react";
import OrderInfo from '../../components/OrderRow';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import RateBlock from '../../components/RateBlock';

import "./orders.css"

const Orders = () => {

  const axiosPrivate = useAxiosPrivate();
  const [ordersData, setOrders] = useState([]);


  useEffect(() => {
    // Fetch data from the backend
    const fetchOrdersData = async () => {
        try {
            const response = await axiosPrivate.get("/api/v1/orders/get");
            const orders = response.data.map(order => ({
                id: order.order_id,
                status: order.status,
                price: order.price,
                delivryLocation: order.delivery_location,
                deliveryPerson: order.delivery_person,
                deliveryTk: order.delivery_tk,
                deliveryPrice: order.delivey_price,
                trackNumber: order.track_number,
                date: order.create_dt,
            }));
            
            setOrders(orders);
        } catch (error) {
            console.error("Error fetching cart data:", error);
        }
    };

    fetchOrdersData();
}, [axiosPrivate]);

  const orders = ordersData.length > 0 ? (ordersData.map((order) => {
    return <OrderInfo 
      order={order}
      key={order.id}
     />
})) : (
  <p className="title-1">Добавьте товары в корзину и совершите свой первый заказ!</p>
)



  return (
    
    <div>
      <header className="section-orders__header">
      <RateBlock />
            <div className="container">
                <h1 className="title-1">Заказы</h1>
            </div>
        </header>



      {orders}
    </div>
  );
}

export default Orders;