import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./profile.css"

const Profile = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const logout = useLogout();
    const signOut = async () => {
        await logout();
        navigate('/');
    }

    const [user, setUser] = useState({
        username: '',
        email: '',
        phone: '',
        telegram: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value
        }));
    };

    const handleEdit = (e) => {
        e.preventDefault();
        setIsEditing(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here, e.g., send data to backend
        setIsEditing(false);
        
        axiosPrivate.post('/api/v1/user/edit', user)
      .then(response => {
        console.log('Form Data Submitted:', response.data);
        // Handle success response
      })
      .catch(error => {
        console.error('There was an error submitting the form!', error);
        alert('Ошибка при обновлении данных: ', error)
        // Handle error response
      });
    };

    useEffect(() => {
        // Replace 'your-backend-url' with your actual backend URL
        axiosPrivate.get("/api/v1/user/get")
          .then(response => {
            setUser(response.data);
          })
          .catch(error => {
            alert(error);
          });
      }, [axiosPrivate]);


    return ( 
        <main className="profile-section">
            <h1 className="title-1">Профиль</h1>
            <form className="profile-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>ФИО:</label>
                    <input
                        type="text"
                        name="username"
                        value={user.username}
                        onChange={handleChange}
                        disabled={!isEditing}
                        className="form-input"
                    />
                </div>
                <div className="form-group">
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        disabled={true}
                        className="form-input"
                    />
                </div>
                <div className="form-group">
                    <label>Телефон:</label>
                    <input
                        type="tel"
                        name="phone"
                        value={user.phone}
                        onChange={handleChange}
                        disabled={!isEditing}
                        className="form-input"
                    />
                </div>
                <div className="form-group">
                    <label>Telegram:</label>
                    <input
                        type="text"
                        name="telegram"
                        value={user.telegram}
                        onChange={handleChange}
                        disabled={!isEditing}
                        className="form-input"
                    />
                </div>
                {isEditing ? (
                    <button type="submit" className="form-button">Сохранить</button>
                ) : (
                    <button type="button" onClick={handleEdit} className="form-button">Изменить</button>
                )}
            </form>
            <div className="container">
                <button onClick={signOut} className="logout-button">Выйти</button>
            </div>
        </main>
     );
}
 
export default Profile;