

import { NavLink } from "react-router-dom"
import { useState } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag, faBox, faUser } from '@fortawesome/free-solid-svg-icons'

import logo from "./../../img/icons/Pwb_wide.png"
import "./navbar.css"

function Navbar () {
    const activeLink = "nav-list__link nav-list__link--active"
    const normalLink = "nav-list__link"

    const activeIcon = "cart-icon active-icon"
    const normalIcon = "cart-icon"

    const openMenu = "nav-list--open"
    const closedMenu = "nav-list"
    
    const [menuOpen, setMenuOpen] = useState(false)

    return (
    <nav className="nav">
    <div className="container">
        <div className="nav-row">
            <NavLink to="/" className="logo">
            <img src={logo} alt="" className="logo"/>
            </NavLink>

            <div className="menu" onClick={() => {
                        setMenuOpen(!menuOpen)
                    }}>
                        <span></span>
                        <span></span>
                        <span></span>
                </div>

            <ul className={menuOpen ? openMenu : closedMenu}>
                <li>
                    <NavLink to="/" className={({isActive}) => isActive ? activeLink : normalLink}>
                    Главная
                    </NavLink>
                </li>

                <li>
                    <NavLink to="/request" className={({isActive}) => isActive ? activeLink : normalLink}>
                     Заявка на расчет
                    </NavLink>
                </li>
                
                <li>
                    <NavLink to="/buyforme" className={({isActive}) => isActive ? activeLink : normalLink}>
                    Заказать
                    </NavLink>
                </li>

                <li>
                    <NavLink to="/tracking" className={({isActive}) => isActive ? activeLink : normalLink}>
                     Отслеживание
                    </NavLink>
                </li>

                <li>
                    <NavLink to="/contacts" className={({isActive}) => isActive ? activeLink : normalLink}>
                    Контакты
                    </NavLink>
                </li>

                <li>
                    <NavLink to="/faq" className={({isActive}) => isActive ? activeLink : normalLink}>
                    FAQ
                    </NavLink>
                </li>
                    
                <li >
                    <NavLink to="/cart" className={({isActive}) => isActive ? activeIcon : normalIcon}>
                    
                    <FontAwesomeIcon icon={faShoppingBag} />

                    {//<span className="cart-count">3</span>
                    }
                    
                    </NavLink>

                    <NavLink to="/orders" className={({isActive}) => isActive ? activeIcon : normalIcon}>
                    
                    <FontAwesomeIcon icon={faBox} />
        
                    </NavLink>

                    <NavLink to="/profile" className={({isActive}) => isActive ? activeIcon : normalIcon}>
                    
                    <FontAwesomeIcon icon={faUser} />
        
                    </NavLink>
                </li>

            </ul>
        </div>
    </div>
</nav>
)
}

export default Navbar;

