import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ScrollToTop from "./utils/scrollToTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./components/navbar/Navbar"
import Footer from "./components/footer/Footer"

import { AuthProvider } from './context/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop />
      <AuthProvider>
      <Navbar />
      <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
        <Footer />
      </AuthProvider>
      </BrowserRouter>
  </React.StrictMode>
);


