
import useAuth from "./useAuth";
import axios from "../api/axios";


const LOGIUT_URL = '/api/v1/auth/signout';

const useLogout = () => {

    const { setAuth, setPersist } = useAuth();

    const logout = async () => {
        setAuth({});
        setPersist(false);
        try {
            await axios(LOGIUT_URL, {
                withCredentials: true
            })
        } catch (err) {
            console.error(err)
        }
    }
    
    return logout;
}
 
export default useLogout;