import "./header.css"
import { Link } from "react-router-dom";

const Header = () => {
    return ( 
        <header className="header">
        <div className="header__wrapper">
            <h1 className="header__title">
                <strong>PWB <em>Logistics</em></strong><br />
                
            </h1>
            <h1 className="header__desc">International Logistic Company</h1>
            <div className="header__text">
                <p>Надежные, эффективные и безопасные услуги доставки из Китая в Россию</p>
            </div>
            <div className="header__buttons">
            <Link to="./request" className="btn">Оставить заявку</Link>
            <Link to="./buyforme" className="btn">Сделать заказ</Link>
            </div>
        </div>
    </header>
     );
}
 
export default Header;
