import nike from "./../img/products/nikeairforce.jpg"
import offwhite from "./../img/products/offwhite.jpg"

const projects = [
    {
        title: 'Nike',
        skills: 'white',
        img: nike,
        imgBig: nike,
        gitLink: "https://github.com",
    },
    {
        title: 'OFF-WHITE',
        skills: 'black',
        img: offwhite,
        imgBig: offwhite,
        
    },
]

export {projects}