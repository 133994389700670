import { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../../api/axios';

import "./auth.css"

const USER_REGEX = /^[a-zA-Zа-яёА-ЯЁ]+\s[a-zA-Zа-яёА-ЯЁ]+(\s[a-zA-Zа-яёА-ЯЁ]+)?$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const TG_REGEX = /^[@](?=(?:[0-9_]*[a-z]){3})[a-z0-9_]{5,}$/;
const PHONE_REGEX = /^[+][7][9][0-9]{9}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,24}$/;
const REGISTER_URL = '/api/v1/auth/signup';

const Register = () => {
    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [user, setUser] = useState('');
    const [tg, setTg] = useState('');
    const [phone, setPhone] = useState('');
    
    const [validEmail, setValidEmail] = useState(false);
    const [validName, setValidName] = useState(false);
    const [validTg, setValidTg] = useState(false);
    const [validPhone, setValidPhone] = useState(false); 

    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidTg(TG_REGEX.test(tg));
    }, [tg])

    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone));
    }, [phone])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [email, user, pwd, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v0 = EMAIL_REGEX.test(email);
        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        if (!v0 || !v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            await axios.post(REGISTER_URL,
                JSON.stringify({ email: email, username: user, hash: pwd, telegram: tg, phone: phone }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

 
            setSuccess(true);
            //clear state and controlled inputs
            //need value attrib on inputs for this
            setEmail('');
            setUser('');
            setTg('');
            setPhone('');
            setPwd('');
            setMatchPwd('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        }
    }

    return (
        <>
            {success ? (
                <div className="sign-section">
                    <h1>Регистрация прошла успешно!</h1>
                    <p>
                        <a href="./../login">Войти</a>
                    </p>
                </div>
            ) : (
                <div className="sign-section">
                    <p ref={errRef} className={errMsg ? "errmsg" : "error-offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Регистрация</h1>
                    <form className="sign-form" onSubmit={handleSubmit}>
                        <label htmlFor="email">
                            Email:
                            <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="email"
                            ref={emailRef}
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                            aria-invalid={validEmail ? "false" : "true"}
                            aria-describedby="emailnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                        />
                        <p id="emailnote" className={userFocus && email && !validEmail ? "instructions" : "error-offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Необходим корректный email<br />
                        </p>

                        <label htmlFor="username">
                            Имя Фамилия:
                            <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="username"
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                        />
                        <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "error-offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            от 4 до 24 символов.<br />
                            Должен начинаться с буквы.
                        </p>

                        <label htmlFor="telegram">
                            Telegram:
                            <FontAwesomeIcon icon={faCheck} className={validTg ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validTg || !tg ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="telegram"
                            autoComplete="off"
                            onChange={(e) => setTg(e.target.value)}
                            value={tg}
                            required
                            aria-invalid={validTg ? "false" : "true"}
                            aria-describedby="tgnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                        />
                        <p id="tgnote" className={userFocus && tg && !validTg ? "instructions" : "error-offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Логин телеграм<br />
                            Должен начинаться с @.
                        </p>

                        <label htmlFor="telegram">
                            Телефон:
                            <FontAwesomeIcon icon={faCheck} className={validPhone ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validPhone || !phone ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="phone"
                            autoComplete="off"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            required
                            aria-invalid={validPhone ? "false" : "true"}
                            aria-describedby="phonenote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                        />
                        <p id="phonenote" className={userFocus && phone && !validPhone ? "instructions" : "error-offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Номер телефона в формате +79999999999<br />
                        </p>


                        <label htmlFor="password">
                            Пароль:
                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                            aria-invalid={validPwd ? "false" : "true"}
                            aria-describedby="pwdnote"
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}
                        />
                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "error-offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            от 8 до 24 символов.<br />
                            Должен содержать заглавные, строчные буквы и цифры.
                        </p>


                        <label htmlFor="confirm_pwd">
                            Подтверждение пароля:
                            <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="password"
                            id="confirm_pwd"
                            onChange={(e) => setMatchPwd(e.target.value)}
                            value={matchPwd}
                            required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                        />
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "error-offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Должен совпадать в введенным паролем.
                        </p>

                        <button disabled={!validEmail || !validPhone || !validTg || !validName || !validPwd || !validMatch ? true : false}>Зарегестрироваться</button>
                    </form>
                    <p>
                        Уже есть аккаунт?<br />
                        <span className="line">
                            {/*put router link here*/}
                            <a href="./login">Войти</a>
                        </span>
                    </p>
                </div>
            )}
        </>
    )
}

export default Register