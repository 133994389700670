import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import priceFormatter from "../../utils/priceFormatter";
import { convertPrice } from '../../utils/priceConverter';



const CartRid = ({ product, deleteProduct }) => {
    const expanded = true

    const [convertedPrice, setConvertedPrice] = useState('');

  useEffect(() => {
    const fetchAndConvertPrice = async () => {
      const result = await convertPrice(product.priceTotal);
      setConvertedPrice(result);
    };

    fetchAndConvertPrice();
  }, [product.priceTotal]);


    const ridsinfo = product.scas.map((scas) => {
        return (
            <tr>
                <td><span style={styles.value}>{scas.size}</span></td>
                <td><span style={styles.value}>{scas.color}</span></td>
                <td><span style={styles.value}>{scas.amount}</span></td>
            </tr>
        )
    })


  return (
    <div style={styles.container}>
      
        <div style={styles.row}>
        <div style={styles.img}><img src={'http://localhost:8080/api/v1/image/'+product.image} style={styles.img} alt={product.name}/></div>
          <span style={styles.label}>{product.name}</span>
          <span style={styles.value}>{convertedPrice}</span>
          <button style={styles.delButton} onClick={()=>{deleteProduct(product.id)}}>X</button>
        </div>
        <div style={expanded ? styles.info : styles.info}>

        <table style={styles.infoData}>
                <tr>
            <td><span style={styles.label}>Ссылка: </span></td>
            <td><span style={styles.value}>{product.link}</span></td>
            </tr>
            <tr>
            <td><span style={styles.label}>Модель: </span></td>
            <td><span style={styles.value}>{product.model}</span></td>
            </tr>
            <tr>
            <td><span style={styles.label}>Категория: </span></td>
            <td><span style={styles.value}>{product.category}</span></td>
            </tr>
            <tr>
            <td><span style={styles.label}>Цена: </span></td>
            <td><span style={styles.value}>{priceFormatter(product.price)} ¥</span></td>
            </tr>
            <tr>
            <td><span style={styles.label}>Вес: </span></td>
            <td><span style={styles.value}>{product.weight} кг</span></td>
            </tr>
            
            </table>
        
        
            <table style={styles.infoData}>
                <tr>
            <td><span style={styles.label}>Размер</span></td>
            <td><span style={styles.label}>Цвет</span></td>
            <td><span style={styles.label}>Количество</span></td>
            </tr>
            {ridsinfo}
            </table>

            {product?.comment ? (
              <div style={{margin: 'auto'}}>
              <span style={styles.label}>Комментарий: </span>
              <span style={styles.value}>{product.comment}</span>
              </div>
            ) : <></>
            }
           
            
            
            
        </div>
    </div>
  );
};

CartRid.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sum: PropTypes.number.isRequired,
};

const styles = {
  container: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '0px',
    maxWidth: '800px',
    margin: '0 auto',
    marginBottom: '20px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: '#dfdfdf'
  },
  hidden: {
    display: 'none'
  },
  infoData: {
    textAlign: 'center',
    padding: '20px'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  img: {
    height: '100px',
    borderRadius: '10px'
  },
  label: {
    fontWeight: 'bold',
  },
  value: {
    color: '#555',
  },
  delButton: {
    marginRight: '50px',
  }
};

export default CartRid;