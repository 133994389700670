import React, { useState } from 'react'

import Question from '../components/question/Question';

const data = [
    {
     question: 'В Какие страны доступна доставка?',
     answer: 'Мы доставляем в Россию, Казахстан и Беларусь.' ,
    },
    {
     question: 'Сколько стоит доставка?',
     answer: 'Тарифы сильно зависят от вида товара, количества мест, плотности и веса груза. Если вы хотите узнать точную стоимость доставки, то напишите нашему менеджеру и предоставьте необходимые для расчета данные.',
    },
    {
     question: 'Какой минимальный заказ?',
     answer: 'У нас нет ограничений по минимальному заказу. Однако, все заказы до 20кг отправляются сборным грузом по тарифу 7$ за кг.',
    },
    {
        question: 'Что делать если пришел бракованный или поврежденный товар?',
        answer: 'Условия возврата и обмена брака вы должны согласовывать непосредственно с поставщиком товара.\nЕсли вам пришел поврежденный товар и он доставлялся в упаковке, которую рекомендовал наш менеджер - мы компенсируем стоимость поврежденных товаров. Если ваш товар был утерян, компенсация производится только при наличии страховки.',
    },
    {
        question: 'Как получить груз? Если я не в Москве?',
        answer: 'Груз можно получить на одном из пунктов выдачи в Москве: ТЯК "Москва, Садовод, Южные ворота. Если вы находитесь не в Москве, то мы отправим вам товар выбранной вами транспортной компанией из доступных: КИТ, ПЭК, Желдор, CDEK, Magic Trans, Деловые Линии.',
    },
    {
        question: 'Какие гарантии?',
        answer: 'Мы не скрываем свое лицо. У нас есть чат и канал с отзывами от реальных клиентов. Также у нас есть свой YouTube - канал где мы рассказываем про работу с Китаем на собственном примере. Также есть вохможность личной встречи для оформления сделки и можем работать по договору от юрлица (+7% к стоимости товаров).',
    },
    {
        question: 'Можно заказать сразу товары из разных категорий?',
        answer: 'Для оптимизации затрат мы стараемся и рекомендуем вам разделять разные товары в разные посылки. В случае, если вы все таки смешиваете разные категории товаров в одном грузе - мы будем вести рассчет по более дорогому тарифу среди товаров в этом грузе.',
    },
    {
        question: 'Как быть уверенным, что поставщик не обманет?',
        answer: 'Перед выкупом мы связываемся с поставщиком и узнаем наличие товара. При подозрении в недобросовестности мы не совершаем сделки в личных чатах. Также первое время мы всегда сотрудничаем только через маркетплейсы и оформляем сделки там. В таком случае ваши средства защищены маркетплейсом.',
    },
    {
        question: 'За какое время вы выкупаете товар?',
        answer: 'Ваш товар будет оплачен поставщику в течение 48 часов с момента оплаты заказа. Если в вашем заказе более 20 позиций от разных поставщиков - срок может быть увеличен.',
    },
    {
        question: 'Почему сборка заказа затянулась?',
        answer: 'После оплаты товара сроки сборки заказа зависят только от поставщиков. Значение имеет выбранная поставщиком служба доставки, удаленность поставщика от нашего склада и то, как быстро он соберет и отправит ваш товар. Чем больше поставщиков в заказе - тем дольше сроки доставки. К сожалению, мы не можем на это повлиять. Для ускорения сборки доставки рекомендуем в одном заказе использовать не более 1-2 поставщиков.',
    },
    {
        question: 'Можно заказать в любой город России?',
        answer: 'Да, конечно. Вы можете заказать доставку в любой город России. Сначала все товары приходят в Москву. Далее мы отправим вам товар выбранной вами транспортной компанией из доступных: КИТ, ПЭК, Желдор, CDEK, Magic Trans, Деловые Линии.',
    },
    {
        question: 'Можете ли вы найти товар по фотографии?',
        answer: 'Да, без проблем. Мы уже более двух лет практикуем поиск товара по фотографии для наших клиентов. Для этого необходимо написать нашему менеджеру и он вам поможет.',
    },
    {
        question: 'Как мне срочно получить образец товара?',
        answer: 'У нас есть услуга "Супер экспресс доставка". Стоимость 30-35$ за кг в зависимости от вида товара и его размеров. Минимальная стоимость доставки - 1кг. С помощью это услуги образец, или просто нужный товар, будет у вас примерно через 5-7 дней с момента оплаты товара.',
    },
    {
        question: 'Для чего нужна страховка товара?',
        answer: 'В случае, если ваш товар при доставке из Китая будет утерян - мы вернем вам полную стоимость товаров.',
    },
    {
        question: 'Какую упаковку мне выбрать?',
        answer: 'На выбор доступны следующие виды упаковки: Мешок+скотч, мешок + картонные уголки, мешок + деревянная обрешетка, палетт + деревянная обрешетка, палет + деревянный ящик. В зависимости от типа товара и того, насколько важна его сохранность от механических воздействий при доставке мы рекомендуем выбирать разные типы упаковки. Например, для одежды достаточно мешка и скотча. Для медицинского оборудования настаиваем на использовании палетта с деревянным ящиком.',
    },
    {
        question: 'Как рассчитать доставку?',
        answer: 'Тарифы сильно зависят от вида товара, количества мест, плотности и веса груза. Если вы хотите узнать точную стоимость доставки, то пришлите нашему менеджеру следующие данные: Тип товара, его фото, вес, объем, метод упаковки, количество мест и стоимость товаров. В таком случае мы сможем максимаольно точно назвать стоимость доставки.',
    },
    {
        question: 'Где искать товар, если я не могу поехать в Китай?',
        answer: 'Самый простой способ - китайские маркетплейсы: 1688.com , tobao.com , PinDuoDuo и Alibaba.com . Также в открытом доступе можно найти множество баз поставщиков. Если у вас есть понимание того, какой товар вам нужен - мы можем найти его по фото и описанию.',
    },
    {
        question: 'Какие методы доставки доступны?',
        answer: `Супер экспресс: 1-3 дня с нашего склада. 
Быстрое авто: 12-18 дней (для некоторых категорий товаров 9-15 дней)
Стандартное авто: 20-25 дней
Поезд (от 300кг): 25-35 дней 
Море (от контейнера): 35-35 дней. 
Чем дольше срок доставки - тем ниже стомиость доставки. 
Сроки доставки указаны с момента отправки с нашего склада в Китае до получения на нашем складе в Москве.`,
    },
    {
        question: 'Какие сроки доставки?',
        answer: 'Срок доставки зависит от типа товара и выбранного метода доставки. Примерные сроки доставки вы можете посмотреть в предыдущем вопросе.',
    },
    {
     question: 'Что делать если я получил не весь товар?',
     answer: `Перед отправкой товара мы прикладываем чек лист о полученных товарах на нашем складе и их количестве. Вы сразу видите - каких товаров не достает. За эти товары мы запрашиваем возврат у поставщика и эти деньги доступны на вашем балансе. Вы можете учесть их в следующем заказе или вернуть на свою карту.
Если перечень или количетсво полученных вами товаров отличается от чек листа, напишите нам и мы проверим информацию. Для решения такой ситуации обязательно иметь видеос  распаковкой посылки. Также убедитесь, что посылка не была вскрыта на таможне (это случается часто, стандартная процедура). Это будет сразу заметно. Если вы заметили следы вскрытия - взвешайте товар и пришлите фото. Если вес отличается от того, который был до отправки - мы компенсируем разницу.`,
    },
    {
     question: 'Почему вес товара немного отличается на ваших весах и на моих?',
     answer: `Разные весы: Бренды и типы весов (электронные или механические) могут давать разные результаты.
Влажность воздуха: Уровень влажности влияет на вес. Например, древесина и бумага могут поглощать влагу и становиться тяжелее. В Китае влажнее, чем в России, поэтому вес может измениться.
Поверхность взвешивания: Неровности или грязь на поверхности весов могут искажать показания, особенно с тяжелым грузом.

При получении товара проверьте соответствие количества. Если оно соответствует, но вес отличается, это связано с одним из этих факторов.`,
    }
   ];

const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
       };

    return (
        <main className="section">
        <div className="container">
                <h1 className="title-1">Ответы на вопросы</h1>

               <div className='container'>
    {data.map((item, index) => (
    <Question
     key={index}
     question={item.question}
     answer={item.answer}
     isOpen={activeIndex === index}
     onClick={() => handleItemClick(index)}
    />
   ))}
  </div> 

        </div>
    </main>
    )
}

export default Faq;