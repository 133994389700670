import { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";

import axios from '../../api/axios';
const LOGIN_URL = '/api/v1/auth/signin';

const Login = () => {
    const { setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/"

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.get(LOGIN_URL,
                {
                    auth: {
                        username: user,
                        password: pwd
                    },
                    withCredentials: true
                }
            );

            const accessToken = response.data?.access_token;
            setAuth({ user, accessToken });
            setUser('');
            setPwd('');
            navigate (from, {replace: true})
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    const togglePersist = () => {
        setPersist(prev => !prev);
    }

    useEffect(() => {
        localStorage.setItem("persist", persist);
    },[persist])

    return ( 
            <section className="sign-section">
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <h1>Вход</h1>
                <form className="sign-form" onSubmit={handleSubmit}>
                    <label htmlFor="username">Email:</label>
                    <input
                        type="text"
                        id="username"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        required
                    />

                    <label htmlFor="password">Пароль:</label>
                    <input
                        type="password"
                        id="password"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                    />
                    <button>Войти</button>
                    <div className="persistCheck">
                        <input 
                            type="checkbox"
                            id="persist"
                            onChange={togglePersist}
                            checked={persist}
                        />
                        <label htmlFor="persist">Запомнить меня</label>
                    </div>
                </form>
                <p>
                    Нет аккаунта?<br />
                    <span className="line">
                        {/*put router link here*/}
                        <Link to="./../register">Зарегестрироваться</Link>
                    </span>
                </p>
            </section>

     );
}
 
export default Login;