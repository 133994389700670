import React, { useState } from 'react';
import axios from '../../api/axios';

import "./request.css"

const Request = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        telegram: '',
        comment: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setError(''); // Clear error on input
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('/api/v1/request', formData);
            setSubmitted(true); // Mark as submitted
        } catch (err) {
            setError('Ошибка при отправке заявки. Пожалуйста попробуйте позже или свяжитсь  снами в Telegram.');
        }
    };

    if (submitted) {
        return (
            <main className="section">
                <div className="container">
                    <h1 className="title-1">Спасибо за вашу заявку!</h1>
                    <p style={{textAlign: "center"}}>Мы свяжемся с вами в ближайшее время.</p>
                </div>
            </main>
        );
    }

    return (
        <main className="section">
            <div className="container">
                <h1 className="title-1">Заявка на расчет</h1>
                <h2 className="title-2" style={{textAlign: "center", maxWidth: "70%", margin: "auto"}}>Есть вопросы или хотите оставить заявку на расчет?</h2>
                <p style={{textAlign: "center", marginBottom: "20px"}}>Заполните форму ниже и мы обязательно свяжемся с вами и бесплатно проконсультируем</p>

                <form onSubmit={handleSubmit} className="feedback-form">
                    <div className="input-row">
                        <input
                            type="text"
                            name="name"
                            placeholder="Имя"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="phone"
                            placeholder="Телефон"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="telegram"
                            placeholder="Telegram"
                            value={formData.telegram}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <textarea
                        name="comment"
                        placeholder="Комментарий"
                        value={formData.comment}
                        onChange={handleInputChange}
                        rows="4"
                    ></textarea>

                    {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}

                    <button type="submit" className="submit-button">Отправить</button>
                </form>
            </div>
        </main>
    );
};

export default Request;
