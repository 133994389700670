import HowItWorksSection from "../components/howItWorks/HowItWorks";
import Header from "./../components/header/Header"

import { Link } from "react-router-dom";

const Home = () => {
    return ( 
        <>
        <Header />
        <HowItWorksSection />


        <main className="section">
            <div className="container">

                    <ul className="content-list">
                    <h2 className="title-2">Почему мы?</h2>
                        <li className="content-list__item">
                            
                            <h3 className="title-1">Надежность и оперативность</h3>
                            <p>Мы гарантируем своевременную доставку ваших товаров из китайских рынков в Россию. Наши опытные специалисты следят за каждым этапом процесса, обеспечивая высокую точность и безопасность перевозки.</p>
                        </li>
                        <li className="content-list__item">
                        <h3 className="title-1">Конкурентные цены</h3>
                            <p>Мы предлагаем доступные и прозрачные тарифы, без скрытых наценок. Наши клиенты всегда уверены, что получают лучшие условия на рынке.</p>
                        </li>
                        <li className="content-list__item">
                        <h3 className="title-1">Индивидуальный подход</h3>
                            <p>Каждый клиент для нас важен. Мы внимательно учитываем все пожелания и особенности заказа, предоставляя персонализированные решения, которые удовлетворяют конкретные потребности.</p>
                        </li>
                        
                        <li className="content-list__item">
                        <h3 className="title-1">Прямые связи с поставщиками</h3>
                            <p>Благодаря налаженным отношениям с проверенными китайскими поставщиками, мы можем предложить нашим клиентам эксклюзивные товары по выгодным ценам.</p>
                        </li>

                        <li className="content-list__item">
                        <h3 className="title-1">Высокий уровень сервиса</h3>
                            <p>Наши сотрудники всегда готовы ответить на любые вопросы и помочь с решением любых проблем. Мы ценим каждого клиента и стремимся обеспечить лучший уровень обслуживания.</p>
                        </li>
                    </ul>
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '20vh'}}>
                        <Link to="./request" className="btn">Связаться</Link>
                    </div>
            </div>
            
        </main>
    </>
     );
}
 
export default Home;