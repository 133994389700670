import "./footer.css"

import telegram from "./../../img/icons/telegramWhite.png"
import whatsapp from "./../../img/icons/whatsappWhite.png"

const Footer = () => {
    return ( 
    <footer className="footer">
    <div className="container">
        <div className="footer__wrapper">
            <div className="social">
                <a href="https://t.me/+7af7jJVqTBE2ZWQy" target="_blank" rel="noopener noreferrer"><img src={telegram} alt="@pwb_logistics" style={{maxWidth: '50px'}}/></a>
                <a href="http://wa.me/+79266709262" target="_blank" rel="noopener noreferrer"><img src={whatsapp} alt="8 (926) 670-92-62" style={{maxWidth: '50px'}}/></a>
            </div>
            <div className="copyright">
                <p>© 2024 PWB Logistics</p>
            </div>
        </div>
    </div>
</footer> 
);
}
 
export default Footer;