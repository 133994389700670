// OrderInfo.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import { convertPrice } from '../utils/priceConverter';

const statusMap = {
  4: 'Ожидает оплаты',
  5: 'Оплачен',
  6: 'Оформлен',
  7: 'Собран',
  8: 'В пути на склад',
  9: 'Отправлен получателю'
};

const OrderInfo = ({ order }) => {
  const [convertedPrice, setConvertedPrice] = useState('');

  useEffect(() => {
    const fetchAndConvertPrice = async () => {
      const result = await convertPrice(order.price);
      setConvertedPrice(result);
    };

    fetchAndConvertPrice();
  }, [order.price]);

  return (
    <NavLink to={`/order/${order.id}`}>
      <div style={styles.container}>
        <div style={styles.header}>
          <span style={styles.label}>Заказ {order.id}</span>
        </div>
        <div style={styles.info}>
          <div style={styles.row}>
            <span style={styles.label}>Дата заказа:</span>
            <span style={styles.value}>{new Date(order.date).toLocaleDateString()}</span>
          </div>
          <div style={styles.row}>
            <span style={styles.label}>Сумма:</span>
            <span style={styles.value}>{convertedPrice}</span>
          </div>
          <div style={styles.row}>
            <span style={styles.label}>Статус:</span>
            <span style={styles.value}>{statusMap[order.status]}</span>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

OrderInfo.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
};

const styles = {
  container: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '0px',
    maxWidth: '800px',
    margin: '0 auto',
    marginBottom: '20px'
  },
  header: {
    backgroundColor: '#dfdfdf',
    padding: '10px',
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px'
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  label: {
    fontWeight: 'bold',
  },
  value: {
    color: '#555',
  },
};

export default OrderInfo;
