import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import "./buyforme/buyforme.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";



const NewOrder = () => {
  
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  const [recipient, setRecipient] = useState('');
  const [transportCompany, setTransportCompany] = useState('СДЕК');
  const [phone, setPhone] = useState('');
  const [deliverySpeed, setDeliverySpeed] = useState('fast');

  const axiosPrivate = useAxiosPrivate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      delivery_location: address,
      delivery_person: recipient,
      delivery_person_phone: phone,
      delivery_speed: deliverySpeed,
      delivery_tk: transportCompany,
    };

    axiosPrivate.post('/api/v1/orders/add', formData)
      .then(response => {
        console.log('Form Data Submitted:', response.data);
        // Handle success response
      })
      .catch(error => {
        console.error('There was an error submitting the form!', error);
        // Handle error response
      });
      setTimeout(function() {
        navigate('/orderadded')}, 700);
  };

  

  return (
    <section style={{paddingTop: '70px'}}>
    <header className="section-cart__header">
            <div className="container">
                <h1 className="title-1">Оформление заказа</h1>
            </div>
        </header>
    <form onSubmit={handleSubmit} className="container-buy">
        <div className='item-block'>
        <label htmlFor="address">Адрес получения:</label>
      <div className="input-container">
        
        
        <input
          type="text"
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />
      </div>
      <label htmlFor="recipient">Получатель:</label>
      <div className="input-container">
        
        <input
          type="text"
          id="recipient"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
          required
        />
      </div>
      <label htmlFor="recipient">Номер телефона:</label>
      <div className="input-container">
        
        <input
          type="text"
          id="recipient"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
      </div>

      <label>Скорость доставки в Россию:</label>
          <div className="radio-container">
            <label style={{display: 'flex', flexDirection: 'row'}}>
              <input
                type="radio"
                name="deliverySpeed"
                value="fast"
                checked={deliverySpeed === 1}
                onChange={() => setDeliverySpeed(1)}
                required
              />
              Быстрая (авиа)
            </label>
            <label>
              <input
                type="radio"
                name="deliverySpeed"
                value="medium"
                checked={deliverySpeed === 2}
                onChange={() => setDeliverySpeed(2)}
              />
              Средняя (наземная)
            </label>
            <label>
              <input
                type="radio"
                name="deliverySpeed"
                value="slow"
                checked={deliverySpeed === 3}
                onChange={() => setDeliverySpeed(3)}
              />
              Медленная (ж/д)
            </label>
          </div>

      <label htmlFor="transportCompany">Транспортная компания по России:</label>
      <div className="input-container">
        
      <select
          id="transportCompany"
          value={transportCompany}
          onChange={(e) => setTransportCompany(e.target.value)}
          required
        >
          
          <option value="СДЕК">СДЕК</option>
          <option value="BoxBerry">BoxBerry</option>
          <option value="PonyExpress">PonyExpress</option>
          <option value="Почта России">Почта России</option>
        </select>
      </div>
      </div>
      <div className="buttons">
      <button type="submit">Оформить заказ</button>
      </div>
    </form>
    </section>
  );
};

export default NewOrder;