import "./header.css"

const Header = () => {
    return ( 
        <header className="header-buy">
        <div className="header__wrapper">
            <h1 className="header__title">
                <strong>Заказ<em> товаров</em></strong><br />
            </h1>
            <div className="header__text">
                <p>Выбирайте товары из китайских интернет-магазинов и копируйте ссылки на них. Вставьте ссылки в поле добавления товара и заполните дополнительную информацию.</p>
            </div>
        </div>
    </header>
     );
}
 
export default Header;
