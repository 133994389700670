import React, { useState } from 'react';
import axios from '../../api/axios';

import './tracking.css';

const Tracking = () => {
    const [trackingNumber, setTrackingNumber] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [trackingData, setTrackingData] = useState([]);
    const [trackingInfo, setTrackingInfo] = useState([]);

    // Regex for validating the tracking number (example format)
    //const trackingNumberRegex = /^[a-zA-Z]{3}\d{7}[a-zA-Z]-\d$/;

    const handleInputChange = (e) => {
        setTrackingNumber(e.target.value);
        if (error) {
            setError('');
        }
    };

    const handleSubmit = async () => {
        /*
        if (!trackingNumberRegex.test(trackingNumber)) {
            setError('Некорректный формат трек-номера');
            return;
        }
            */

        setLoading(true); 
        setError('');
        setTrackingData([]);
        setTrackingInfo([]);

        try {
            const response = await axios.get(`/api/v1/tracking/${trackingNumber}`);
            setTrackingData(response.data.data || []);
            setTrackingInfo(response.data.info)
        } catch (err) {
            setError('Ошибка отслеживания');
        } finally {
            setLoading(false); 
        }
    };

    return (
        <main className="section">
            <div className="container">
                <h1 className="title-1">Отслеживание заказа</h1>

                <div className="tracking-container" style={{ display: 'flex', alignItems: 'center' }}>
                    <input 
                        type="text" 
                        className={`tracking-input ${error ? 'input-error' : ''}`} 
                        placeholder="Введите номер отслеживания" 
                        value={trackingNumber}
                        onChange={handleInputChange}
                    />
                    <button className="tracking-submit" onClick={handleSubmit} disabled={loading}>
                        {loading ? 'Загрузка...' : 'Отследить'}
                    </button>
                </div>

                {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
                
                {trackingData.length > 0 && (
                    <div>
                        <div className='tracking-info'>
                        <div><b>Вес:</b> {trackingInfo.kgs} кг</div>
                        <div><b>Объем:</b> {trackingInfo.cbm} м<sup>3</sup></div>
                        <div><b>Количество:</b> {trackingInfo.ctns}</div>
                        </div>
                        <div className='tracking-date'>
                        <div>Предполагаемая дата доставки: </div><div style={{whiteSpace: "nowarp"}}>{trackingInfo.yjrecdate}</div>
                        </div>

                    <div className="tracking-timeline">
                        {trackingData.map((item, index) => (
                            <div className="timeline-item" key={index}>
                                <div className="timeline-dot"></div>
                                <div className="timeline-content">
                                    <div className="timeline-date">{item.ChangeTime}</div>
                                    <div className="timeline-info">
                                        {item.TrackInfo.trim() || 'Нет информации'}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    </div>
                )}

                {loading && (
                    <div className="loading-spinner">
                        <div className="spinner"></div>
                    </div>
                )}
            </div>
        </main>
    );
};

export default Tracking;
