import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./buyforme.css";
import HeaderBuy from '../../components/header/HeaderBuy';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Buyforme = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [items, setItems] = useState([
        {
            link: '',
            name: '',
            image: null,
            model: '',
            price: '',
            sizeColorAmounts: [{ size: '', color: '', amount: '' }],
            category: '',
            weight: '',
            comment: '',
            isEditing: true,
            errors: {}
        }
    ]);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newItems = [...items];

        // Handle nested properties for sizeColorAmounts
        if (name.startsWith("sizeColorAmounts")) {
            const [fieldName, arrIndex, field] = name.split(".");
            if (field === "amount") {
                newItems[index][fieldName][arrIndex][field] = parseInt(value); // Convert to integer
            } else {
                newItems[index][fieldName][arrIndex][field] = value;
            }

            // Clear the error state for this field if value is entered
            if (value.trim() !== '') {
                delete newItems[index].errors[`sizeColorAmounts.${arrIndex}.${field}`];
            } else {
                // If value is empty, set error for this field
                newItems[index].errors[`sizeColorAmounts.${arrIndex}.${field}`] = true;
                
            } 
        } else if (name === "price" || name === "weight") {
            newItems[index][name] = parseFloat(value); // Convert to float
        } else {
            newItems[index][name] = value;

            // Clear the error state for this field if value is entered
            if (value.trim() !== '') {
                delete newItems[index].errors[name];
            } else {
                // If value is empty, set error for this field
                newItems[index].errors[name] = true;
            }
        }

        setItems(newItems);
    };

    const handleImageChange = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            const newItems = [...items];
            newItems[index].image = file;
            setItems(newItems);
        }
    };

    const handleAddItem = () => {
        setItems([
            ...items,
            {
                link: '',
                name: '',
                image: null,
                model: '',
                price: '',
                sizeColorAmounts: [{ size: '', color: '', amount: '' }],
                category: '',
                weight: '',
                comment: '',
                isEditing: true,
                errors: {}
            }
        ]);
    };

    const handleDeleteItem = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    };

    const handleAddSizeColorAmount = (index) => {
        const newItems = [...items];
        newItems[index].sizeColorAmounts.push({ size: '', color: '', amount: '' });
        setItems(newItems);
    };

    const handleDeleteSizeColorAmount = (itemIndex, sizeColorIndex) => {
        const newItems = [...items];
        newItems[itemIndex].sizeColorAmounts.splice(sizeColorIndex, 1);
        setItems(newItems);
    };

    const toggleEditItem = (index) => {
        const newItems = [...items];
        newItems[index].isEditing = !newItems[index].isEditing;
        setItems(newItems);
    };

    const validateInputs = (item) => {
        const errors = {};

        if (!item.link) {
            errors.link = true;
        } else if (!isValidUrl(item.link)) {
            errors.link = true;
        }

        if (!item.name) {
            errors.name = true;
        }

        if (!item.image) {
            errors.image = true;
        }

        if (!item.model) {
            errors.model = true;
        }

        if (!item.price) {
            errors.price = true;
        }

        if (!item.category) {
            errors.category = true;
        }

        if (!item.weight) {
            errors.weight = true;
        }

        item.sizeColorAmounts.forEach((sizeColorAmount, index) => {
            if (!sizeColorAmount.size || !sizeColorAmount.color || !sizeColorAmount.amount) {
                if (!errors[`sizeColorAmounts.${index}`]) {
                    errors[`sizeColorAmounts.${index}`] = {};
                }
                if (!sizeColorAmount.size) {
                    errors[`sizeColorAmounts.${index}.size`] = true;
                }
                if (!sizeColorAmount.color) {
                    errors[`sizeColorAmounts.${index}.color`] = true;
                }
                if (!sizeColorAmount.amount) {
                    errors[`sizeColorAmounts.${index}.amount`] = true;
                }
            }
        });

        return errors;
    };

    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };

    const handleSaveItem = (index) => {
        const currentItem = items[index];
        const errors = validateInputs(currentItem);
        
        if (Object.keys(errors).length > 0) {
            const newItems = [...items];
            newItems[index].errors = errors;
            setItems(newItems);

            return;
        }

        toggleEditItem(index);
        console.log('Item saved:', items[index]);
    };

    const handleAddToCart = async () => {
        try {
            const formData = new FormData();
    
            items.forEach((item, index) => {
                formData.append(`items[${index}][link]`, item.link);
                formData.append(`items[${index}][name]`, item.name);
                formData.append(`items[${index}][model]`, item.model);
                formData.append(`items[${index}][price]`, item.price);
                formData.append(`items[${index}][category]`, item.category);
                formData.append(`items[${index}][weight]`, item.weight);
                formData.append(`items[${index}][comment]`, item.comment);
                if (item.image) {
                    formData.append(`items[${index}][image]`, item.image);
                }
    
                item.sizeColorAmounts.forEach((sca, scaIndex) => {
                    formData.append(`items[${index}][sizeColorAmounts][${scaIndex}][size]`, sca.size);
                    formData.append(`items[${index}][sizeColorAmounts][${scaIndex}][color]`, sca.color);
                    formData.append(`items[${index}][sizeColorAmounts][${scaIndex}][amount]`, sca.amount);
                });
            });
    
            await axiosPrivate.post('/api/v1/cart/add', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            console.log('Items submitted successfully:', items);
            navigate('/cart');
        } catch (error) {
            console.error('Error submitting items:', error);
            alert('Ошибка при добавлении в корзину:', error)
        }
    };

    const allItemsSaved = items.every(item => !item.isEditing);

    return (
        <>
            <HeaderBuy />
            <div className="container-buy">

                {items.map((item, index) => (
                    <div key={index} className={`item-block ${item.isEditing ? 'editing' : 'saved'}`}>
                        <label>Ссылка на товар:</label>
                        <div className="input-container">
                            <input
                                type="text"
                                name="link"
                                value={item.link}
                                onChange={(event) => handleInputChange(index, event)}
                                className={item.errors && item.errors.link ? 'error' : ''}
                                disabled={!item.isEditing}
                            />
                            <div className="tooltip-container">
                                <span className="tooltip-icon">?</span>
                                <span className="tooltip">Вставьте актуальную ссылку на товар, который вы хотели бы заказать</span>
                            </div>
                        </div>
                        <label>Название товара:</label>
                        <div className="input-container">
                            <input
                                type="text"
                                name="name"
                                value={item.name}
                                onChange={(event) => handleInputChange(index, event)}
                                className={item.errors && item.errors.name ? 'error' : ''}
                                disabled={!item.isEditing}
                            />
                            <div className="tooltip-container">
                                <span className="tooltip-icon">?</span>
                                <span className="tooltip">Укажите полное название товара</span>
                            </div>
                        </div>
                        <label>Изображение товара:</label>
                        <div className="input-container">
                            <input
                                type="file"
                                name="image"
                                accept="image/*,.png,.jpg,.jpeg,.gif,.web,.webp,"
                                onChange={(event) => handleImageChange(index, event)}
                                className={item.errors && item.errors.image ? 'error' : ''}
                                disabled={!item.isEditing}
                            />
                            <div className="tooltip-container">
                                <span className="tooltip-icon">?</span>
                                <span className="tooltip">Загрузите изображение товара</span>
                            </div>
                        </div>
                        <label>Модель товара (на китайском языке):</label>
                        <div className="input-container">
                            <input
                                type="text"
                                name="model"
                                value={item.model}
                                onChange={(event) => handleInputChange(index, event)}
                                className={item.errors && item.errors.model ? 'error' : ''}
                                disabled={!item.isEditing}
                            />
                            <div className="tooltip-container">
                                <span className="tooltip-icon">?</span>
                                <span className="tooltip">Укажите модель выбранного товара как указано на сайте</span>
                            </div>
                        </div>
                        <label>Цена товара в юанях:</label>
                        <div className="input-container">
                            <input
                                type="number"
                                name="price"
                                value={item.price}
                                onChange={(event) => handleInputChange(index, event)}
                                className={item.errors && item.errors.price ? 'error' : ''}
                                disabled={!item.isEditing}
                            />
                            <div className="tooltip-container">
                                <span className="tooltip-icon">?</span>
                                <span className="tooltip">Укажите цену в юанях за выбранную модель товара</span>
                            </div>
                        </div>
                        <div className="size-color-amount-fields">
                            {item.sizeColorAmounts.map((sizeColorAmount, sizeColorIndex) => (
                                <div key={sizeColorIndex} className="size-color-amount-field">
                                    <div className="size-color-amount-field-block">
                                    <div className="size-color-amount-field-group">
                                    <label>Размер:</label>
                                    <input
                                        type="text"
                                        name={`sizeColorAmounts.${sizeColorIndex}.size`}
                                        value={sizeColorAmount.size}
                                        onChange={(event) => handleInputChange(index, event)}
                                        className={item.errors && item.errors[`sizeColorAmounts.${sizeColorIndex}.size`] ? 'error' : ''}
                                        disabled={!item.isEditing}
                                    />
                                    </div>
                                    <div className="size-color-amount-field-group">
                                    <label>Цвет:</label>
                                    <input
                                        type="text"
                                        name={`sizeColorAmounts.${sizeColorIndex}.color`}
                                        value={sizeColorAmount.color}
                                        onChange={(event) => handleInputChange(index, event)}
                                        className={item.errors && item.errors[`sizeColorAmounts.${sizeColorIndex}.color`] ? 'error' : ''}
                                        disabled={!item.isEditing}
                                    />
                                    </div>
                                    <div className="size-color-amount-field-group">
                                    <label>Количество:</label>
                                    <input
                                        type="number"
                                        name={`sizeColorAmounts.${sizeColorIndex}.amount`}
                                        value={sizeColorAmount.amount}
                                        onChange={(event) => handleInputChange(index, event)}
                                        className={item.errors && item.errors[`sizeColorAmounts.${sizeColorIndex}.amount`] ? 'error' : ''}
                                        disabled={!item.isEditing}
                                    />
                                    </div>
                                    </div>
                                    <button className="delete-size-color-amount" disabled={item.sizeColorAmounts.length > 1 && item.isEditing ? false: true} onClick={() => handleDeleteSizeColorAmount(index, sizeColorIndex)}>X</button>
                                    
                                    
                                </div>
                            ))}
                            <button className="add-size-color-amount" disabled={item.isEditing ? false: true} onClick={() => handleAddSizeColorAmount(index)}>+</button>
                        </div>
                        <label>Категория товара:</label>
                        <div className="input-container">
                            <input
                                type="text"
                                name="category"
                                value={item.category}
                                onChange={(event) => handleInputChange(index, event)}
                                className={item.errors && item.errors.category ? 'error' : ''}
                                disabled={!item.isEditing}
                            />
                            <div className="tooltip-container">
                                <span className="tooltip-icon">?</span>
                                <span className="tooltip">Укажите категорию товара</span>
                            </div>
                        </div>
                        <label>Вес товара:</label>
                        <div className="input-container">
                            <input
                                type="number"
                                name="weight"
                                value={item.weight}
                                onChange={(event) => handleInputChange(index, event)}
                                className={item.errors && item.errors.weight ? 'error' : ''}
                                disabled={!item.isEditing}
                            />
                            <div className="tooltip-container">
                                <span className="tooltip-icon">?</span>
                                <span className="tooltip">Укажите примерный вес  еденицы товара в килограммах</span>
                            </div>
                        </div>
                        <label>Комментарий:</label>
                        <div className="input-container">
                            <input
                                type="text"
                                name="comment"
                                value={item.comment}
                                onChange={(event) => handleInputChange(index, event)}
                                className={item.errors && item.errors.comment ? 'error' : ''}
                                disabled={!item.isEditing}
                            />
                            <div className="tooltip-container">
                                <span className="tooltip-icon">?</span>
                                <span className="tooltip">Комментарий к товару</span>
                            </div>
                        </div>
                        <div className="block-buttons">
                            {item.isEditing ? (
                                <button className="save" onClick={() => handleSaveItem(index)}>Сохранить</button>
                            ) : (
                                <button className="edit" onClick={() => toggleEditItem(index)}>Изменить</button>
                            )}
                            {items.length > 1 && (
                                        <button className="delete" onClick={() => handleDeleteItem(index)}>Удалить</button>
                                    )}
                            
                        </div>
                    </div>
                ))}
                <div className="buttons">
                    <button onClick={handleAddItem}>Добавить товар</button>
                    <div className="cart-tooltip-container">
                        <button onClick={handleAddToCart} id="add-to-cart-button" disabled={!allItemsSaved}>
                            Добавить в корзину
                            {!allItemsSaved && (
                                <div className="tooltip">Все товары должны быть сохранены перед добавлением в корзину</div>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Buyforme;
