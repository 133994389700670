import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CartRid from "./CartRid";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import priceFormatter from "../../utils/priceFormatter";
import { convertPriceRub } from '../../utils/priceConverter';
import RateBlock from "../RateBlock";

import "./style.scss"

const Cart = () => {
    const axiosPrivate = useAxiosPrivate();

    const [convertedPrice, setConvertedPrice] = useState('');

    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState({
        price: 0,
        count: 0,
    })

    useEffect(() => {
        // Fetch data from the backend
        const fetchCartData = async () => {
            try {
                const response = await axiosPrivate.get("/api/v1/cart/get");
                const cartData = response.data.map(product => ({
                    id: product.rid,
                    name: product.name,
                    image: product.image,
                    link: product.link,
                    model: product.model,
                    price: product.price,
                    amount: product.amount,
                    scas: product.scas,
                    priceTotal: product.price * product.amount,
                    category: product.category,
                    weight: product.weight,
                    comment: product.comment,
                }));
                
                setCart(cartData);
            } catch (error) {
                console.error("Error fetching cart data:", error);
            }
        };

        fetchCartData();
    }, [axiosPrivate]);

    useEffect(()=>{
        setTotal({
            price: cart.reduce((prev, curr)=> prev + curr.priceTotal, 0),
            count: cart.reduce((prev, curr)=> prev + curr.amount, 0),
        })
    }, [cart])

    useEffect(() => {
        const fetchAndConvertPrice = async () => {
          const result = await convertPriceRub(total.price);
          setConvertedPrice(result);
        };
    
        fetchAndConvertPrice();
      }, [total.price]);

    const deleteProduct = (id) => {
        setCart((cart)=> cart.filter((product)=> id !== product.id))
        axiosPrivate.get(`/api/v1/cart/del/${id}`)
            .then(response => {
                console.log('Successfully deleted product:', response.data);
                // Handle any success actions if needed
            })
            .catch(error => {
                console.error('Error deleting product:', error);
                alert('Товар не удален ', error)
                // Handle error appropriately
            });
    }

    

    const products = cart.map((product) => (
            <CartRid 
                product={product} 
                key={product.id} 
                deleteProduct={deleteProduct} 
            />
        ))
    

    return ( 
        <section className="section-cart">
            <RateBlock />
        <header className="section-cart__header">
            
            <div className="container">
                <h1 className="title-1">Корзина</h1>
            </div>
        </header>
        <div className="section-cart__body">
            <div className="container">

                <section className="cart">
                   

                    {products}

                    
                    
                        {cart.length > 0 ? (
           
                <div className="cart-totals">
                <div className="cart-total">Товары: {priceFormatter(convertedPrice)} руб.</div>
                <div className="cart-total">Сервисный сбор: {priceFormatter(convertedPrice * 0.2)} руб.</div>
                <br></br>
                <div className="cart-totalprice">Итого: {priceFormatter(convertedPrice * 1.2)} руб.</div>
                <div className="cart-disclamer">Итоговая сумма указана без стоимости доставки. Стоимость доставки будет расчитана менеджером и согласована с вами после оформления заказа.</div>

                <Link to="../neworder" className="button-order">Оформить заказ</Link>
                </div>
            
        ) : (
            <p className="title-1">Корзина пуста</p>
        )}                    
                </section>

                

            </div>
        </div>
    </section>
     );
}
 
export default Cart;