
import telegram from "./../img/icons/telegramColored.png"
import whatsapp from "./../img/icons/whatsapp.png"

const Contacts = () => {
    return ( 
        <main className="section">
        <div className="container">
                <h1 className="title-1">Контакты</h1>

                <ul className="content-list">
                    <li>
                        <h2 className="title-2">Адрес</h2>
                        <p>Москва, Мажоров переулок, д. 14</p>
                    </li>
                    <li>
                        <h2 className="title-2">Telegram / WhatsApp</h2>
                        <div style={{display: 'flex', justifyContent: 'center', gap: '20px'}}>
                        <a href="https://t.me/+7af7jJVqTBE2ZWQy" target="_blank" rel="noopener noreferrer"><img src={telegram} alt="@pwb_logistics" style={{maxWidth: '50px'}}/></a>
                        <a href="http://wa.me/+79266709262" target="_blank" rel="noopener noreferrer"><img src={whatsapp} alt="8 (926) 670-92-62" style={{maxWidth: '50px'}}/></a>
                        </div>
                    </li>
                    
                </ul>

        </div>
    </main>
     );
}
 
export default Contacts;