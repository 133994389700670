import React from 'react';
import { NavLink } from "react-router-dom";
import './howItWorks.css';
import step1Img from './../../img/steps/step1.png'; // Replace with your actual image paths
import step2Img from './../../img/steps/step2.jpg';
import step3Img from './../../img/steps/step3.jpg';


const HowItWorksSection = () => (
    <section className="how-it-works">
      <h2>Как это работает</h2>
      <div className="steps">
        <div className="step">
          <div className="step-number">1</div>
          <div className="step-content">
            <h3>Выбор товаров</h3>
            <p>Выбирайте товары из китайских интернет-магазинов и копируйте ссылки на них.</p>
            <img src={step1Img} alt="Step 1" className="step-image" />
          </div>
        </div>
        <div className="step">
          <div className="step-number">2</div>
          <div className="step-content">
            <h3>Оформление заказа</h3>
            <p>Вы можете оставить заявку на расчет, указав свои данные. Менеджер свяжется с вами для уточнения деталей.</p>
            <NavLink to={`/request`}>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px', marginBottom: '15px'}}>
            <button className='btn'>Заявка на расчет</button>
            </div>
            </NavLink>
            <h3 style={{textAlign: 'center'}}>ЛИБО</h3>
            <p>Вставьте ссылки на выбранные товары и заполните данные о них в разделе формирования заказа. После чего проверьте собранные товары в корзине и оформите ваш заказ.</p>
            <NavLink to={`/buyforme`}>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px', marginBottom: '15px'}}>
            <button className='btn'>Заказать</button>
            </div>
            </NavLink>
          </div>
        </div>
        <div className="step">
          <div className="step-number">3</div>
          <div className="step-content">
            <h3>Оплата товаров</h3>
            <p>Менеджер свяжется с вами для согласования финальной стоимости и оплаты ваших товаров.</p>
            <img src={step2Img} alt="Step 3" className="step-image" style={{maxHeight: '150px'}}/>
          </div>
        </div>
        <div className="step">
          <div className="step-number">4</div>
          <div className="step-content">
            <h3>Отправка заказа</h3>
            <p>Ваш заказ будет отправлен по вашему адресу, вы сможете отследить его в разделе отслеживание или на странице заказа.</p>
            <img src={step3Img} alt="Step 4" className="step-image" style={{maxHeight: '130px'}}/>
          </div>
        </div>
      </div>
    </section>
  );
  
  export default HowItWorksSection;
