import { useNavigate } from 'react-router-dom';

const OrderAlert = () => {
    const navigate = useNavigate();
    return (
            <div className="container">
                <br></br>
                <br></br>
                <h1 className="title-1">Заказ оформлен!</h1>
                <div style={{textAlign: 'center', }}>
                <p>В ближайшее время менеджер свяжется с вами для подтверждения деталей.</p>
                <p>Подробности можно узнать на странице заказов</p>
                </div>
                <button style={styles.button} onClick={() => navigate('/orders')}>Заказы</button>

            
            </div>
    )
}

const styles = {
    button: {
        backgroundColor: '#008a37',
        color: 'white',
        padding: '5px',
        borderRadius: '5px',
        borderColor: 'transparent',
        margin: '20px auto',
        width: '100px'
    }
}

export default OrderAlert;