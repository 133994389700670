import "./styles/main.css"

import {Routes, Route } from "react-router-dom";


import Home from "./pages/Home";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import Contacts from "./pages/Contacts";
import Projects from "./pages/Projects";
import Layout from "./utils/Layout";
import Profile from "./pages/profile/Profile";
import Orders from "./pages/Orders/Orders";
import Order from "./pages/Order/Order";
import RequireAuth from "./utils/RequireAuth";
import PersistLogin from "./utils/PersistLogin";
import NewOrder from "./pages/NewOrder";
import OrderAlert from "./pages/OrderAlert";
import Faq from "./pages/Faq"
import Tracking from "./pages/tracking/Tracking";
import Request from "./pages/request/Request";


import Buyforme from "./pages/buyforme/Buyforme";
import Cart from "./components/cart/Cart";

function App() {
  return (
    
           
      <Routes>
      <Route path="/" element={<Layout />}>

        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        
        <Route path="/projects" element={<Projects />} />
        
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/tracking" element={<Tracking />} />
        <Route path="/request" element={<Request />} />

        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth /> } >
            <Route path="/buyforme" element={<Buyforme />} />
            
            <Route path="/cart" element={<Cart />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/order/:id" element={<Order />} />
            <Route path="/neworder" element={<NewOrder />} />
            <Route path="/orderadded" element={<OrderAlert />} />
          </Route>
        </Route>
      </Route>
      </Routes>
    

  );
}

export default App;
