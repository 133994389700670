import axios from '../api/axios';
import React, { useState, useEffect } from 'react';


const RateBlock = () =>{
    const [rate, setRate] = useState('');

    useEffect(() => {
        const fetchRate = async () => {
          const result = await axios.get('/api/v1/rate');
          setRate(result.data);
        };
    
        fetchRate();
      }, []);  

    return (
        <div style={styles.rate}>
            1¥ = {rate}₽
        </div>
    )
}

const styles = {
  rate: {
    paddingRight: '30px',
    textAlign: 'right',
    marginBottom: '20px',
    fontWeight: 'bold'
  }
}

export default RateBlock